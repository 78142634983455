import { compactMap } from 'helper/array'
import { ClippedRateQueryParams } from 'lib/query/clippedRateQuery'
import { HotelBookingQueryParams } from 'lib/query/hotelBookingQuery'
import { HotelDetailQueryParams } from 'lib/query/hotellDetailQuery'

export const buildQueryString = (
  params: Record<string, string | string[] | number | number[] | boolean | undefined | null> | undefined
): string => {
  if (!params) return ''
  const chunks = compactMap(Object.keys(params), key => {
    const value = params[key]
    if (value === null || value === undefined) return
    if (Array.isArray(value)) {
      return value.map(v => `${key}=${v}`)
    }
    return `${key}=${value}`
  })
  if (chunks.length === 0) return ''
  return `?${chunks.flatMap(d => d).join('&')}`
}

export class Routes {
  static lp() {
    return `/lp`
  }

  static signUp() {
    return `/signup`
  }

  static signUpCompleted() {
    return `/signup/complete`
  }

  static signIn() {
    return `/signin`
  }

  static signOut() {
    return `/signout`
  }

  static resetPassword() {
    return `/reset-password`
  }

  static setNewPassword() {
    return `/reset-password/new`
  }

  static resetPasswordSent() {
    return `/reset-password/sent`
  }

  static myPage() {
    return Routes.travels()
  }

  static travels() {
    return `/travels`
  }

  static travel(id: string) {
    return `/travels/${id}`
  }

  static changeTravelDate(id: string) {
    return `${this.travel(id)}/change-date`
  }

  static changeTravelDateComplete(id: string) {
    return `${this.changeTravelDate(id)}/complete`
  }

  static voucher(travelId: string) {
    return `/travels/${travelId}/vouchers`
  }

  static itineraries(travelId: string) {
    return `/travels/${travelId}/itineraries`
  }

  static terms() {
    return `/terms`
  }

  static news() {
    return `/news`
  }

  static newsDetail(id: string) {
    return `/news/${id}`
  }

  static privacy() {
    return `https://www.worldcompass.co.jp/policy/`
  }

  static company() {
    return `https://www.worldcompass.co.jp/about/company/`
  }

  static mailToContact() {
    return `mailto:contact@support.hoteco-wcc.com`
  }

  static transactionLaw() {
    return `/transaction-law`
  }

  static pdfPrintGuide() {
    return `/pdf-print-guide`
  }

  static help() {
    return `https://hoteco-wcc.tayori.com/q/faq-g/`
  }

  static createAgencyRequests() {
    return `/agency-requests/new`
  }

  static agencyRequestCompleted() {
    return `/agency-requests/complete`
  }

  static employeeInvitations() {
    return `/employee-invitations`
  }

  static searchHotelsTop() {
    return `/`
  }

  static accountSetting() {
    return `/settings/accounts`
  }

  static teamSetting() {
    return `/settings/teams`
  }

  static paymentSetting() {
    return `/settings/payments`
  }

  static branchSetting() {
    return `/settings/branches`
  }

  static searchHotels() {
    return `/hotels`
  }

  static hotelDetail(id: string, params: HotelDetailQueryParams | undefined) {
    return `${this.searchHotels()}/${id}${buildQueryString({ ci: params?.ci, co: params?.co, ocs: params?.ocs })}`
  }

  static bookingComplete(travelId: string) {
    return `/travels/${travelId}/complete`
  }

  static bookingUrl(params: { propertyId: string; roomId: string } & HotelBookingQueryParams) {
    const { propertyId, roomId, ...others } = params
    return `/hotels/${propertyId}/rooms/${roomId}${buildQueryString({ ...others })}`
  }

  static clippedRates(params?: ClippedRateQueryParams) {
    return `/clipped-rates${buildQueryString(params)}`
  }

  static invoices() {
    return `/invoices`
  }
}
