import { Alert, Box, Flex, Heading, HStack, Icon, Link, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react'
import { NextLink } from 'components/wrapper/nextLink'
import { useAuthState } from 'context/auth'
import { useMyselfState } from 'context/myself'
import { FC, useMemo } from 'react'
import { MdMail, MdPhone } from 'react-icons/md'
import { Routes } from 'routes'

const headerContentWidth = '1240px'

export const Header: React.FC = () => {
  const { initialized, myself, error } = useMyselfState()
  const { firebaseUser } = useAuthState()
  const hasAccount = useMemo(() => !!myself?.id, [myself?.id])

  return (
    <>
      <Flex
        direction="column"
        minWidth={headerContentWidth}
        width="full"
        backgroundColor="white"
        alignItems="center"
        borderBottomWidth={1}
        borderBottomColor="gray.40"
        borderTopColor="lightBlue.100"
        borderTopWidth={4}
      >
        <Flex h={['72px']} w={headerContentWidth} alignItems="center" justifyContent="space-between" px={5} mx="auto">
          <Link as={NextLink} href={hasAccount ? Routes.searchHotelsTop() : Routes.lp()} display="contents">
            <Heading as="h2" w="140px" h="auto">
              <img src="/assets/logo.png" alt="HoteCo" width="full" height="full" />
            </Heading>
          </Link>

          <HStack spacing={6}>
            {initialized && !error && (
              <>
                {hasAccount ? (
                  <MemberRightComponent name={myself?.employee?.name || firebaseUser?.displayName || undefined} />
                ) : (
                  <AnonymousRightComponent />
                )}
                <Flex py={1} px={3} borderColor="gray.100" borderWidth={1} rounded="lg" align="center">
                  {myself?.employee?.agencyBranch.companyId && (
                    <VStack mr={6} spacing={0} align="flex-start" fontSize={14} fontWeight="medium">
                      <Text>会社コード</Text>
                      <Text>{myself?.employee?.agencyBranch.companyId}</Text>
                    </VStack>
                  )}
                  <VStack spacing={1} align="flex-start">
                    <HStack spacing={2}>
                      <Icon as={MdPhone} boxSize={5} color="blue.500" />
                      <Text fontSize={18} fontWeight="bold" color="blue.500" lineHeight={1}>
                        03-6455-3561
                      </Text>
                    </HStack>
                    <Link href={Routes.mailToContact()} target="_blank">
                      <HStack spacing={2}>
                        <Icon as={MdMail} boxSize={5} color="blue.500" />
                        <Text fontSize={14} fontWeight="medium" color="blue.500" lineHeight={1}>
                          お問い合わせ
                        </Text>
                      </HStack>
                    </Link>
                  </VStack>
                </Flex>
              </>
            )}
          </HStack>
        </Flex>
        {myself?.employee?.agencyBranch.status === 'freezed' && (
          <Alert status="error" justifyContent="center">
            <Text fontWeight="bold">
              アカウントの取引が停止されているため、新規予約を行うことができません。詳しくは運営までお問い合わせください。
            </Text>
          </Alert>
        )}
      </Flex>
    </>
  )
}

const AnonymousRightComponent: FC = () => {
  return (
    <>
      <Link as={NextLink} href={Routes.signUp()} color="headerLink" fontSize={14} fontWeight="medium">
        新規会員登録
      </Link>
      <Link as={NextLink} href={Routes.signIn()} color="headerLink" fontSize={14} fontWeight="medium">
        ログイン
      </Link>
      <Link as={NextLink} href={Routes.help()} color="headerLink" fontSize={14} fontWeight="medium" target="_blank">
        ヘルプ
      </Link>
    </>
  )
}

const MemberRightComponent: FC<{ name?: string }> = ({ name }) => {
  return (
    <>
      <Link as={NextLink} href={Routes.clippedRates()} color="headerLink" fontSize={14} fontWeight="medium">
        プラン比較
      </Link>
      <Box w="1px" h={8} backgroundColor="gray.30" />
      <Link as={NextLink} href={Routes.myPage()} color="headerLink" fontSize={14} fontWeight="medium">
        マイページ
      </Link>
      <Menu autoSelect={false}>
        <MenuButton _hover={{ textDecoration: 'underline' }}>
          <Flex align="center">
            <Link color="headerLink" fontSize={14} fontWeight="medium">
              {name ? name : 'アカウント'}
            </Link>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem as={NextLink} href={Routes.signOut()}>
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>
      <Link as={NextLink} href={Routes.help()} color="headerLink" fontSize={14} fontWeight="medium" target="_blank">
        ヘルプ
      </Link>
    </>
  )
}
