import { Box, Container, Flex } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Footer } from '../footer'
import { Header } from '../header'

type Props = {
  headerBottomSlot?: ReactNode
  children: ReactNode
}

export const AppContainer: React.FC<Props> = ({ headerBottomSlot, children }) => {
  return (
    <Flex flexDirection={'column'} width="full" minH="100vh">
      <Box width="full" zIndex={999} sx={{ '@media print': { display: 'none' } }}>
        <Header />
        {headerBottomSlot}
      </Box>
      <Container
        size="content"
        sx={{
          '@media print': {
            minWidth: '1200px',
            width: '1200px',
            size: 'A4 portrait',
          },
        }}
      >
        <Box width="full">{children}</Box>
      </Container>
      <Box mt={'auto'} sx={{ '@media print': { display: 'none' } }}>
        <Footer />
      </Box>
    </Flex>
  )
}
