import { Box, Flex, HStack, Image, Link, Text } from '@chakra-ui/react'
import { default as NextLink } from 'next/link'
import { Routes } from 'routes'

export const Footer: React.FC = () => {
  return (
    <Flex py={12} px={5} minWidth="1240px" w="full">
      <Box w="1200px" mx="auto">
        <Image src="/assets/footer.svg" w="full" h="auto" />

        <HStack spacing={6} mt={14}>
          <Link href={Routes.company()} target="_blank">
            <HStack spacing={2}>
              <Image src="/assets/arrow_right_circle.svg" boxSize={4} />
              <Text lineHeight={1}>運営会社</Text>
            </HStack>
          </Link>
          <Link href={Routes.privacy()} target="_blank">
            <HStack spacing={2}>
              <Image src="/assets/arrow_right_circle.svg" boxSize={4} />
              <Text lineHeight={1}>個人情報保護方針について</Text>
            </HStack>
          </Link>
          <Link as={NextLink} href={Routes.terms()}>
            <HStack spacing={2}>
              <Image src="/assets/arrow_right_circle.svg" boxSize={4} />
              <Text lineHeight={1}>利用規約</Text>
            </HStack>
          </Link>
          <Link href={Routes.mailToContact()} target="_blank">
            <HStack spacing={2}>
              <Image src="/assets/arrow_right_circle.svg" boxSize={4} />
              <Text lineHeight={1}>お問い合わせ</Text>
            </HStack>
          </Link>
        </HStack>

        <Text lineHeight={'24px'} mt={7}>
          観光庁長官登録第1種2104号 / 社団法人 日本海外ツアーオペレーター協会 正会員 249
          <br />© WORLD COMPASS Co., Ltd. All Rights Reserved.
        </Text>
      </Box>
    </Flex>
  )
}
